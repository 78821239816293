import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Aside, Container, Wrapper } from '../../components/Container';
import { CardList, Card, CardWrapper } from '../../components/Card';

const Courses = function () {
  const { t } = useTranslation();

  const [header, setHeader] = useState(null);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const data = t('course', { returnObjects: true });
    const { aside, content } = data || {};

    if (aside && content) {
      setHeader(aside);
      setCourses(content.courses);
    }
  }, [t]);

  return (
    <>
      {header && (
        <Aside>
          <h1>
            {header.title.text}
            <span>{header.title.highlight}</span>
          </h1>
          <h2>{header.subtitle.text}</h2>
          <p>{header.description.text}</p>
        </Aside>
      )}
      <Container>
        <Wrapper>
          <CardList>
            {courses.map(course => (
              <Card key={course.id}>
                <img src={course.image.src} alt={course.title} />
                <CardWrapper>
                  <h4>{course.title}</h4>
                  <p>{course.description}</p>
                  <a href={course.uri} target="_blank" rel="noreferrer">
                    Saiba mais
                  </a>
                </CardWrapper>
              </Card>
            ))}
          </CardList>
        </Wrapper>
      </Container>
    </>
  );
};

export default Courses;
