/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import {
  SiDeezer,
  SiSpotify,
  SiYoutubemusic,
  SiApplemusic,
  SiTidal,
  SiAmazon,
} from 'react-icons/si';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Container,
  Header,
  Wrapper,
  Aside,
  Content,
  Footer,
  Icons,
  Icon,
} from './styles';

const DiscosModal = function () {
  const { id } = useParams();

  const { t } = useTranslation();

  const [detail, setDetail] = useState(null);

  useEffect(() => {
    const data = t('discography', { returnObjects: true });
    const { content } = data || {};
    if (content) {
      const disco = content.discos.find(p => p.id === Number(id));
      setDetail(disco.details);
    }
  }, [id, t]);

  return (
    <Container>
      {detail && (
        <>
          <Header>
            {detail.subtitle && <h2>{detail.subtitle}</h2>}
            <h1>{detail.title}</h1>
            <span>{detail.release}</span>
          </Header>
          <Wrapper>
            <Aside>
              <img src={detail.image.src} alt={detail.image.alt} />
              <p dangerouslySetInnerHTML={{ __html: detail.content.text }} />
            </Aside>
            <Content>
              <p
                dangerouslySetInnerHTML={{ __html: detail.content.description }}
              />

              <Footer>
                <p>Ouça nas principais plataformas digitais:</p>
                <Icons>
                  {detail.spotify && (
                    <Icon
                      href={detail.spotify.uri}
                      title="Spotify"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <SiSpotify size={30} />
                    </Icon>
                  )}
                  {detail.deezer && (
                    <Icon
                      href={detail.deezer.uri}
                      title="Deezer"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <SiDeezer size={30} />
                    </Icon>
                  )}
                  {detail.appleMusic && (
                    <Icon
                      href={detail.appleMusic.uri}
                      title="Apple Music"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <SiApplemusic size={30} />
                    </Icon>
                  )}
                  {detail.youtubeMusic && (
                    <Icon
                      href={detail.youtubeMusic.uri}
                      title="Youtube Music"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <SiYoutubemusic size={30} />
                    </Icon>
                  )}
                  {detail.amazonMusic && (
                    <Icon
                      href={detail.amazonMusic.uri}
                      title="Amazon Music"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <SiAmazon size={30} />
                    </Icon>
                  )}
                  {detail.tidal && (
                    <Icon
                      href={detail.tidal.uri}
                      title="Tidal"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <SiTidal size={30} />
                    </Icon>
                  )}
                </Icons>
              </Footer>
            </Content>
          </Wrapper>
        </>
      )}
    </Container>
  );
};

export default DiscosModal;
