import React from 'react';
import { Route } from 'react-router-dom';

import DefaultLayout from '../pages/_layout/default';
import ModalLayout from '../pages/_layout/modal';

const RouteWrapper = function ({
  component: Component,
  isModal,
  withWrapper = true,
  ...rest
}) {
  const Layout = isModal ? ModalLayout : DefaultLayout;

  return (
    <Route
      {...rest}
      render={props => (
        <Layout withWrapper={withWrapper}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

export default RouteWrapper;
