import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';

import { Container, PhotoWrapper, Photo } from './styles';

const PhotosModal = function () {
  const { id } = useParams();
  const location = useLocation();

  // const { t } = useTranslation();

  const [gallery, setGallery] = useState('');

  useEffect(() => {
    // const photos = t('photo.content.albums', { returnObjects: true });
    // if (photos) {
    //   const photo = photos.find(p => p.id === Number(id));
    //   setGallery(photo);
    // }
    setGallery(location.state.uri);
  }, [id]);

  return (
    <Container>
      {gallery && (
        <PhotoWrapper>
          <Photo
            loading="lazy"
            src={gallery}
            alt="Imagem"
            // alt={`${gallery.title}`}
          />
        </PhotoWrapper>
      )}
    </Container>
  );
};

export default PhotosModal;
