import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Navigation,
  Pagination,
  Autoplay,
  Virtual,
} from 'swiper/core';

// import history from '../../services/history';

import { Aside, Container, Wrapper } from '../../components/Container';

import { PhotoWrapper, Photo } from './styles';

SwiperCore.use([Navigation, Pagination, Autoplay, Virtual]);

const Photos = function () {
  const location = useLocation();

  const { t } = useTranslation();

  const [header, setHeader] = useState(null);
  const [albums, setAlbums] = useState([]);

  useEffect(() => {
    const photo = t('photo', { returnObjects: true });
    const { aside, content } = photo || {};

    if (aside && content) {
      setHeader(aside);
      setAlbums(content.albums.sort((a, b) => b.id - a.id));
    }
  }, [t]);

  return (
    <>
      {header && (
        <Aside>
          <h1>
            {header.title.text}
            <span>{header.title.highlight}</span>
          </h1>
          <h2>{header.subtitle.text}</h2>
          <p>{header.description.text}</p>
        </Aside>
      )}
      <Container>
        <Wrapper>
          {albums.map(photo => (
            <PhotoWrapper key={photo.id}>
              <h3>{photo.title}</h3>
              <Swiper
                virtual
                slidesPerView={7}
                navigation
                pagination
                breakpoints={{
                  768: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                  1440: {
                    slidesPerView: 4,
                  },
                  1520: {
                    slidesPerView: 5,
                  },
                  1720: {
                    slidesPerView: 7,
                  },
                }}
              >
                {photo.uploads.map((upload, index) => (
                  <SwiperSlide key={Number(index)}>
                    <Link
                      to={{
                        pathname: `/photos/${photo.id}`,
                        state: { background: location, uri: upload },
                      }}
                    >
                      <Photo
                        loading="lazy"
                        src={upload}
                        alt={`${photo.title}`}
                      />
                    </Link>
                  </SwiperSlide>
                ))}
              </Swiper>
            </PhotoWrapper>
          ))}
        </Wrapper>
      </Container>
    </>
  );
};

export default Photos;
