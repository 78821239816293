import React, { useCallback } from 'react';
import { MdClose } from 'react-icons/md';

import history from '../../../services/history';

import { Container, Wrapper } from './styles';

const DefaultModal = function ({ withWrapper, children }) {
  const handleBack = useCallback(e => {
    e.stopPropagation();
    history.goBack();
  }, []);

  return (
    <Container>
      <button type="button" onClick={handleBack}>
        <MdClose size={25} />
      </button>

      {withWrapper ? <Wrapper>{children}</Wrapper> : children}
    </Container>
  );
};

export default DefaultModal;
