import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Aside, Container, Wrapper } from '../../components/Container';
import { CardList, Card, CardWrapper } from '../../components/Card';

const Projects = function () {
  const location = useLocation();

  const { t } = useTranslation();

  const [header, setHeader] = useState(null);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const data = t('project', { returnObjects: true });
    const { aside, content } = data || {};

    if (aside && content) {
      setHeader(aside);
      setProjects(content.projects);
    }
  }, [t]);

  return (
    <>
      {header && (
        <Aside>
          <h1>
            {header.title.text}
            <span>{header.title.highlight}</span>
          </h1>
          <h2>{header.subtitle.text}</h2>
          <p>{header.description.text}</p>
        </Aside>
      )}
      <Container>
        <Wrapper>
          <CardList>
            {projects.map(project => (
              <Card key={project.id}>
                <img src={project.image.src} alt={project.title} />
                <CardWrapper>
                  <h4>{project.title}</h4>
                  <p>{project.description}</p>
                  <Link
                    to={{
                      pathname: `/projects/${project.id}`,
                      state: { background: location },
                    }}
                  >
                    Saiba mais
                  </Link>
                </CardWrapper>
              </Card>
            ))}
          </CardList>
        </Wrapper>
      </Container>
    </>
  );
};

export default Projects;
