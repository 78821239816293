import React from 'react';

import Header from '../../../components/Header';
import Footer from '../../../components/Footer';

import { Container, Wrapper, Background } from './styles';

const DefaultLayout = function ({ children }) {
  return (
    <Container>
      <Header />
      <Wrapper>
        {children}
        <Footer />
      </Wrapper>
      <Background />
    </Container>
  );
};

export default DefaultLayout;
