import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Aside, Container } from '../../components/Container';

import { Background } from './styles';

const Home = function () {
  const { t } = useTranslation();

  const [header, setHeader] = useState(null);

  useEffect(() => {
    const data = t('home', { returnObjects: true });
    const { aside } = data || {};

    if (aside) {
      setHeader(aside);
    }
  }, [t]);

  return (
    <>
      {header && (
        <Aside>
          <h1>
            {header.title.text}
            <span>{header.title.highlight}</span>
          </h1>
          <h2>{header.subtitle.text}</h2>
          <p>{header.description.text}</p>
          <a
            href={header.button.uri}
            title={header.button.title}
            target="_blank"
            rel="noreferrer"
          >
            {header.button.name}
          </a>
        </Aside>
      )}
      <Container />
      <Background />
    </>
  );
};

export default Home;
