import styled from 'styled-components';
import { darken } from 'polished';

export const Aside = styled.aside`
  grid-area: aside;

  display: flex;
  flex-direction: column;

  width: 100%;

  padding: 80px 100px 10px 20px;

  h1 {
    display: flex;
    flex-direction: column;

    font-size: 7.4rem;
    font-weight: bold;
    text-transform: uppercase;

    text-shadow: #4d4237 0px 0px 0.5px;

    span {
      color: #4d4237;
    }
  }

  h2 {
    font-size: 2.8rem;
    font-style: italic;
    text-transform: uppercase;
    text-shadow: #4d4237 0px 0px 0.5px;
  }

  p {
    padding: 20px 0;
    color: #4d4237;
    font-size: 1.6rem;
    line-height: 1.6;
    text-align: justify;
    text-shadow: #4d4237 0px 0px 0.5px;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 20px;
    background: #bba66d;
    color: #4d4237;

    box-shadow: #4d4237 0px 0px 5px;

    font-size: 1.8rem;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;

    width: 120px;
    height: 40px;

    transition: all 1s;

    :hover {
      background: ${darken(0.05, '#bba66d')};
    }
  }

  /** tratamento adaptativo para table e mobile */
  @media (max-width: 768px) {
    background: #fdfdfd;
    opacity: 0.9;
    padding: 10px 20px;
    align-items: center;
    justify-content: center;

    h1 {
      align-items: center;
    }

    h2 {
      text-align: center;
    }
  }

  /** tratamento adaptativo para mobile */
  @media (max-width: 425px) {
    h1 {
      font-size: 6rem;
    }

    h2 {
      font-size: 2.4rem;
    }
  }
`;

export const Container = styled.div`
  grid-area: main;

  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  flex: 1;
  padding: 20px 20px 20px 10px;
  height: 100%;
  max-height: calc(100vh - 160px);
  width: 100%;
  overflow-y: auto;

  p {
    line-height: 1.6;
    text-align: justify;
  }

  /** tratamento adaptativo para table e mobile */
  @media (max-width: 768px) {
    padding: 20px;
    max-height: 100%;
    overflow-y: hidden;
  }
`;
