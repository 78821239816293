import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;

  height: 100%;
  max-height: calc(100vh - 10%);
  overflow-y: auto;

  /** tratamento adaptativo para table e mobile */
  @media (max-width: 425px) {
    padding: 20px 10px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  h1 {
    font-size: 7.4rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  h2 {
    font-size: 2.8rem;
  }

  span {
    color: #4d4237;
    font-size: 2.4rem;
  }

  /** tratamento adaptativo para table e mobile */
  @media (max-width: 425px) {
    h1 {
      font-size: 4rem;
    }

    h2 {
      font-size: 2rem;
    }

    span {
      color: #4d4237;
      font-size: 1.6rem;
    }
  }
`;

export const Wrapper = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 35% 1fr;
  grid-template-areas:
    'aside content'
    'aside content';

  p {
    color: #4d4237;
    font-size: 1.6rem;
    line-height: 1.6;
    text-align: justify;
  }

  /** tratamento adaptativo para table e mobile */
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'aside'
      'content';
  }
`;

export const Aside = styled.div`
  grid-area: aside;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;

  img {
    width: 100%;
    height: auto;
    border-radius: 25px;
  }

  p {
    padding-top: 20px;
    font-style: italic;
  }

  /** tratamento adaptativo para table e mobile */
  @media (max-width: 425px) {
    padding: 20px 10px;
  }
`;

export const Content = styled.div`
  grid-area: content;
  padding: 40px 20px;

  display: flex;
  flex-direction: column;

  p {
    flex: 1;
  }

  /** tratamento adaptativo para table e mobile */
  @media (max-width: 425px) {
    padding: 20px 10px;
  }
`;

export const Footer = styled.div`
  padding-top: 80px;
  color: #4d4237;

  p {
    text-align: end;
  }
`;

export const Icons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;
`;

export const Icon = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background: #4d4237;
  color: #fdfdfd;
  height: 32px;
  width: 32px;

  transition: all 1s;

  + a {
    margin-left: 5px;
  }

  svg {
    height: 20px;
    width: auto;
  }

  :hover {
    background: ${darken(0.05, '#4d4237')};
  }
`;
