import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MdMenu, MdClose } from 'react-icons/md';

import logo from '../../assets/logo.png';

import { Container, Logo, Menu, MenuItem } from './styles';

const Header = function () {
  const { pathname } = useLocation();

  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    setShowMenu(false);
  }, [pathname]);

  const handleMenu = useCallback(() => {
    setShowMenu(state => !state);
  }, [showMenu]);

  return (
    <Container>
      <Link to="/" alt="Lucas Bueno Dias">
        <Logo src={logo} />
      </Link>
      <Menu isOpen={showMenu}>
        <ul>
          <MenuItem isSelected={pathname === '/'}>
            <Link to="/">Home</Link>
          </MenuItem>
          <MenuItem isSelected={pathname.startsWith('/about')}>
            <Link to="/about">Biografia</Link>
          </MenuItem>
          <MenuItem isSelected={pathname.startsWith('/courses')}>
            <Link to="/courses">Cursos</Link>
          </MenuItem>
          <MenuItem isSelected={pathname.startsWith('/projects')}>
            <Link to="/projects">Projetos</Link>
          </MenuItem>
          <MenuItem isSelected={pathname.startsWith('/videos')}>
            <Link to="/videos">Vídeos</Link>
          </MenuItem>
          <MenuItem isSelected={pathname.startsWith('/photos')}>
            <Link to="/photos">Fotos</Link>
          </MenuItem>
          <MenuItem isSelected={pathname.startsWith('/discography')}>
            <Link to="/discography">Discografia</Link>
          </MenuItem>
          {/* <MenuItem isSelected={pathname.startsWith('/press')}>
            <Link to="/press">Imprensa</Link>
          </MenuItem> */}
        </ul>
        <button type="button" onClick={handleMenu}>
          {showMenu ? <MdClose size={32} /> : <MdMenu size={32} />}
        </button>
      </Menu>
    </Container>
  );
};

export default Header;
