import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Navigation,
  Pagination,
  Autoplay,
  Virtual,
} from 'swiper/core';

import { Aside, Container, Wrapper } from '../../components/Container';

import { VideoWrapper, Video } from './styles';

SwiperCore.use([Navigation, Pagination, Autoplay, Virtual]);

const Videos = function () {
  const { t } = useTranslation();

  const [header, setHeader] = useState(null);
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    const video = t('video', { returnObjects: true });
    const { aside, content } = video || {};

    if (aside && content) {
      setHeader(aside);
      setVideos(content.videos.sort((a, b) => b.id - a.id));
    }
  }, [t]);

  return (
    <>
      {header && (
        <Aside>
          <>
            <h1>
              {header.title.text}
              <span>{header.title.highlight}</span>
            </h1>
            <h2>{header.subtitle.text}</h2>
            <p>{header.description.text}</p>
          </>
        </Aside>
      )}
      <Container>
        <Wrapper>
          {videos.map(video => (
            <VideoWrapper key={video.id}>
              <h3>{video.title}</h3>
              <Swiper
                virtual
                slidesPerView={5}
                navigation
                pagination
                breakpoints={{
                  768: {
                    slidesPerView: 3,
                  },
                  1024: {
                    slidesPerView: 1,
                  },
                  1440: {
                    slidesPerView: 3,
                  },
                  1520: {
                    slidesPerView: 4,
                  },
                  1720: {
                    slidesPerView: 5,
                  },
                }}
              >
                {video.uploads.map((upload, index) => (
                  <SwiperSlide key={Number(index)}>
                    <Video>
                      <iframe
                        loading="lazy"
                        title={upload.description}
                        src={upload.uri}
                        frameBorder="0"
                        allow="accelerometer;encrypted-media;gyroscope;picture-in-picture"
                        allowFullScreen
                      />
                      <p>{upload.description}</p>
                    </Video>
                  </SwiperSlide>
                ))}
              </Swiper>
            </VideoWrapper>
          ))}
        </Wrapper>
      </Container>
    </>
  );
};

export default Videos;
