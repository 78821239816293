import styled from 'styled-components';

import background from '../../../assets/background.png';

export const Container = styled.main`
  position: relative;
  display: flex;
  flex-direction: column;
  /* height: auto; */
  height: 100%;
  min-height: 100vh;
`;

export const Wrapper = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 580px 1fr;
  grid-template-rows: 1fr 100px;
  grid-template-areas:
    'aside main'
    'footer main';

  /** tratamento adaptativo para telas menores */
  @media (max-width: 1024px) {
    grid-template-columns: 500px 1fr;
    grid-template-rows: 1fr 100px;
    grid-template-areas:
      'aside main'
      'footer main';
  }

  /** tratamento adaptativo para table e mobile */
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 150px;
    grid-template-areas:
      'aside'
      'main'
      'footer';
  }
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: -1;

  background: url(${background}) no-repeat;
  background-size: 95% 100%;
  width: 100%;
  max-width: 580px;

  /** tratamento adaptativo para telas menores */
  @media (max-width: 1024px) {
    max-width: 500px;
  }

  /** tratamento adaptativo para table e mobile */
  @media (max-width: 768px) {
    display: none;
  }
`;
