import styled from 'styled-components';

import background from '../../assets/home-background.png';

export const Container = styled.div`
  grid-area: main;
  flex: 1;
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  background: url(${background}) center no-repeat;
  /* background-size: auto 100%; */
  background-size: cover;
  z-index: -2;

  /** tratamento adaptativo para telas maiores */
  /* @media (min-width: 1400px) {
    background: url(${background}) center no-repeat;
    background-size: auto 120%;
  } */
`;
