import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  overflow: auto;
  width: 100%;
  height: 100%;
`;

export const PhotoWrapper = styled.div`
  /* width: 100%; */
  /* height: 100%; */
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Photo = styled.img`
  border-radius: 25px;
  background: #fdfdfd;
  box-shadow: #4d4237 0px 0px 5px;
  margin: auto;
  display: block;
  width: 60%;
  /* max-width: 900px; */

  /** tratamento adaptativo para table e mobile */
  @media (max-width: 768px) {
    width: 90%;
  }
`;
