import styled from 'styled-components';
import { Aside } from '../../components/Container';

export const Bio = styled(Aside)`
  img {
    border-radius: 160px;
    box-shadow: #4d4237 0px 0px 5px;
    margin-left: 20px;

    max-height: 320px;
    height: 100%;
    max-width: 320px;
    width: 100%;
  }

  /** tratamento adaptativo para table e mobile */
  @media (max-width: 768px) {
    img {
      margin-left: 0;
      max-height: 220px;
      max-width: 220px;
    }
  }
`;
