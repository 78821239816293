import React from 'react';
import { Switch, Redirect, useLocation } from 'react-router-dom';
// import { TransitionGroup, CSSTransition } from 'react-transition-group';

import Route from './Route';

import Home from '../pages/home';
import About from '../pages/about';
import Courses from '../pages/courses';
import Projects from '../pages/projects';
import ProjectModal from '../pages/projects/modal';
import Videos from '../pages/videos';
import Photos from '../pages/photos';
import PhotosModal from '../pages/photos/modal';
import Discos from '../pages/discography';
import DiscosModal from '../pages/discography/modal';

const Routes = function () {
  const location = useLocation();

  // Modal
  const background = location.state && location.state.background;

  return (
    // <TransitionGroup>
    // <CSSTransition key={location.pathname} classNames="fade" timeout={500}>
    <>
      <Switch location={background || location}>
        <Route path="/" exact component={Home} />
        <Route path="/about" component={About} />
        <Route path="/courses" exact component={Courses} />
        <Route path="/projects" exact component={Projects} />
        <Route path="/videos" exact component={Videos} />
        <Route path="/photos" exact component={Photos} />
        <Route path="/discography" exact component={Discos} />
        <Route path="/press" exact component={Home} />
      </Switch>

      {background && (
        <Switch>
          <Route
            path="/photos/:id"
            isModal
            withWrapper={false}
            component={PhotosModal}
          />
          <Route path="/discography/:id" isModal component={DiscosModal} />
          <Route path="/projects/:id" isModal component={ProjectModal} />
        </Switch>
      )}
    </>
    // </CSSTransition>
    // </TransitionGroup>
  );
};

export default Routes;
