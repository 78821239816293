import styled from 'styled-components';

export const CardList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Card = styled.div`
  background: #fdfdfd;
  border-radius: 25px;
  margin: 10px;
  height: 300px;
  width: 100%;
  max-width: 300px;
  box-shadow: #4d4237 0px 0px 5px;

  img {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    height: 150px;
    width: 100%;
    max-width: 350px;
    object-fit: cover;
    object-position: top;
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 150px;

  padding: 5px 5px 10px;

  h4 {
    font-weight: bold;
    font-size: 1.8rem;
    text-align: center;
  }

  p {
    flex: 1;
    color: #4d4237;
    text-align: left;
    font-size: 1.4rem;
  }

  a {
    align-self: flex-end;
    justify-self: flex-end;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0 10px;
    font-size: 1.4rem;
  }
`;

export const CardFooter = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-between;

  svg {
    height: 24px;
    width: auto;
  }
`;
