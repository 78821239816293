import styled from 'styled-components';
import { lighten } from 'polished';

export const PhotoWrapper = styled.div`
  h3 {
    font-size: 1.6rem;
    font-family: 'Montserrat', sans-serif;
    padding: 0 0 10px;
    color: #fdfdfd;
    text-shadow: #4d4237 0px 0px 0.5px;
  }

  .swiper-container {
    display: flex;
    width: 100%;
    max-width: 1300px;
    height: 200px;
    list-style: none;
  }

  .swiper-wrapper {
    width: 100%;
    max-width: 600px;
    justify-self: center;
    align-self: center;
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: #fdfdfd;
  }

  .swiper-pagination-bullet {
    background: ${lighten(0.1, '#fdfdfd')};
  }

  .swiper-pagination-bullet-active {
    background: #fdfdfd;
  }
`;

export const Photo = styled.img`
  border-radius: 25px;
  background: #fdfdfd;
  width: 150px;
  height: 150px;
  margin: 5px;
  box-shadow: #4d4237 0px 0px 5px;
  object-fit: cover;
`;
