import styled from 'styled-components';
import { lighten } from 'polished';

export const VideoContainer = styled.div`
  flex: 1;
  padding: 5px;
  max-height: calc(100vh - 120px);
  overflow-y: auto;
`;

export const VideoWrapper = styled.div`
  > h3 {
    font-size: 1.6rem;
    font-family: 'Montserrat', sans-serif;
    padding: 0 0 10px;
    color: #fdfdfd;
    text-shadow: #4d4237 0px 0px 0.5px;
  }

  .swiper-container {
    display: flex;
    width: 100%;
    max-width: 1300px;
    height: 260px;
    list-style: none;
  }

  .swiper-wrapper {
    width: 100%;
    max-width: 600px;
    justify-self: center;
    align-self: center;
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: #fdfdfd;
  }

  .swiper-pagination-bullet {
    background: ${lighten(0.1, '#fdfdfd')};
  }

  .swiper-pagination-bullet-active {
    background: #fdfdfd;
  }
`;

export const Video = styled.div`
  border-radius: 25px;
  height: 210px;
  width: 210px;
  margin: 5px;
  background: #fdfdfd;
  color: #4d4237;
  box-shadow: #4d4237 0px 0px 5px;

  iframe {
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
    width: 100%;
    height: 120px;
  }

  p {
    font-size: 1.4rem;
    padding: 5px;
    text-align: center;
  }
`;
