import styled, { css } from 'styled-components';

export const Container = styled.header`
  display: flex;
  padding: 20px;
`;

export const Menu = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ul {
    display: flex;
    justify-content: flex-end;

    list-style: none;
    width: 100%;
    padding: 20px 0;
  }

  button {
    display: none;
  }

  @media (max-width: 1400px) {
    ul {
      display: none;

      ${props =>
        props.isOpen &&
        css`
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: #000;
          opacity: 0.9;
          z-index: 9;
        `}
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0;
      background: none;
      color: #bba66d;
      border-radius: 30px;
      width: 60px;
      height: 60px;
      transition: background 1s;

      ${props =>
        props.isOpen &&
        css`
          z-index: 9;
        `}

      :hover {
        background: #bba66d;
        color: #4d4237;
      }
    }
  }
`;

export const MenuItem = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;

  padding: 5px;
  height: 45px;
  width: 130px;

  transition: background 1s;

  + li {
    margin-left: 10px;
  }

  a {
    font-size: 1.6rem;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    text-shadow: #4d4237 0px 0px 0.5px;
  }

  ${props =>
    props.isSelected &&
    css`
      background: #bba66d;

      a {
        color: #4d4237;
      }
    `}
`;

export const Logo = styled.img`
  position: absolute;
  height: 130px;
  width: auto;

  /** tratamento adaptativo para table e mobile */
  @media (max-width: 768px) {
    position: initial;
    height: 80px;
    width: auto;
  }
`;
