import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FaSpotify } from 'react-icons/fa';

import { Aside, Container, Wrapper } from '../../components/Container';
import { CardList, Card, CardWrapper, CardFooter } from '../../components/Card';

const Discos = function () {
  const location = useLocation();

  const { t } = useTranslation();

  const [header, setHeader] = useState(null);
  const [discos, setDiscos] = useState([]);

  useEffect(() => {
    const data = t('discography', { returnObjects: true });
    const { aside, content } = data || {};

    if (aside && content) {
      setHeader(aside);
      setDiscos(content.discos);
    }
  }, [t]);

  return (
    <>
      {header && (
        <Aside>
          <h1>
            {header.title.text}
            <span>{header.title.highlight}</span>
          </h1>
          <h2>{header.subtitle.text}</h2>
          <p>{header.description.text}</p>
        </Aside>
      )}
      <Container>
        <Wrapper>
          <CardList>
            {discos.map(disco => (
              <Card key={disco.id}>
                <img src={disco.image.src} alt={disco.title} />
                <CardWrapper>
                  <h4>{disco.title}</h4>
                  <p>{disco.description}</p>
                  <CardFooter>
                    <a
                      href={t('footer.spotify.uri')}
                      title={t('footer.spotify.title')}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaSpotify />
                    </a>
                    <Link
                      to={{
                        pathname: `/discography/${disco.id}`,
                        state: { background: location },
                      }}
                    >
                      Saiba mais
                    </Link>
                  </CardFooter>
                </CardWrapper>
              </Card>
            ))}
          </CardList>
        </Wrapper>
      </Container>
    </>
  );
};

export default Discos;
