/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { SiSpotify, SiInstagram, SiFacebook, SiYoutube } from 'react-icons/si';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Container,
  Header,
  Wrapper,
  Aside,
  Content,
  Footer,
  Icons,
  Icon,
} from './styles';

const ProjectModal = function () {
  const { id } = useParams();

  const { t } = useTranslation();

  const [detail, setDetail] = useState(null);

  useEffect(() => {
    const data = t('project', { returnObjects: true });
    const { content } = data || {};
    if (content) {
      const project = content.projects.find(p => p.id === Number(id));
      setDetail(project.details);
    }
  }, [id, t]);

  return (
    <Container>
      {detail && (
        <>
          <Header>
            {detail.subtitle && <h2>{detail.subtitle}</h2>}
            <h1>{detail.title}</h1>
            {detail.release && <span>{detail.release}</span>}
          </Header>
          <Wrapper>
            <Aside>
              <img src={detail.image.src} alt={detail.image.alt} />
              <p dangerouslySetInnerHTML={{ __html: detail.content.text }} />
            </Aside>
            <Content>
              <p
                dangerouslySetInnerHTML={{ __html: detail.content.description }}
              />

              <Footer>
                <p>Conheça as plataformas digitais:</p>
                <Icons>
                  {detail.spotify && (
                    <Icon
                      href={detail.spotify.uri}
                      title="Spotify"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <SiSpotify size={30} />
                    </Icon>
                  )}
                  {detail.instagram && (
                    <Icon
                      href={detail.instagram.uri}
                      title="Instagram"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <SiInstagram size={30} />
                    </Icon>
                  )}
                  {detail.facebook && (
                    <Icon
                      href={detail.facebook.uri}
                      title="Facebook"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <SiFacebook size={30} />
                    </Icon>
                  )}
                  {detail.youtube && (
                    <Icon
                      href={detail.youtube.uri}
                      title="Youtube"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <SiYoutube size={30} />
                    </Icon>
                  )}
                </Icons>
              </Footer>
            </Content>
          </Wrapper>
        </>
      )}
    </Container>
  );
};

export default ProjectModal;
