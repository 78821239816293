import styled from 'styled-components';

export const Container = styled.div`
  background: rgba(0, 0, 0, 0.7);

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 9;

  button {
    position: absolute;
    top: 0;
    right: 0;

    border: 0;
    border-radius: 25px;

    margin: 15px;

    height: 50px;
    width: 50px;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  /** tratamento adaptativo para table e mobile */
  @media (max-width: 768px) {
    button {
      border-radius: 20px;
      margin: 10px;
      height: 40px;
      width: 40px;
    }
  }

  /** tratamento adaptativo para table e mobile */
  @media (max-width: 425px) {
    button {
      border-radius: 14px;
      margin: 5px;
      height: 25px;
      width: 25px;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;

  background: #fff;
  border-radius: 25px;
  box-shadow: 0px 0px 10px #00000033;

  height: 90%;
  width: 85%;
  max-width: 1920px;

  /** tratamento adaptativo para table e mobile */
  @media (max-width: 768px) {
    border-radius: 10px;
    width: 95%;
  }
`;
