/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import imgBio from '../../assets/bio.png';

import { Container, Wrapper } from '../../components/Container';

import { Bio } from './styles';

const About = function () {
  const { t } = useTranslation();

  const [header, setHeader] = useState(null);
  const [about, setAbout] = useState(null);

  useEffect(() => {
    const data = t('about', { returnObjects: true });
    const { aside, content } = data || {};

    if (aside && content) {
      setHeader(aside);
      setAbout(content.about);
    }
  }, [t]);

  return (
    <>
      {header && (
        <Bio>
          <h1>{header.title.text}</h1>
          <img src={imgBio} alt={header.image.title} />
        </Bio>
      )}
      <Container>
        {about && (
          <Wrapper>
            <p dangerouslySetInnerHTML={{ __html: about.text }} />
          </Wrapper>
        )}
      </Container>
    </>
  );
};

export default About;
