import React, { useState, useEffect } from 'react';
import { SiInstagram, SiYoutube, SiFacebook, SiSpotify } from 'react-icons/si';
import { useTranslation } from 'react-i18next';

import { Container, Wrapper, Media, Icons, Icon } from './styles';

const Footer = function () {
  const { t } = useTranslation();

  const [footer, setFooter] = useState(null);

  useEffect(() => {
    const data = t('footer', { returnObjects: true });
    if (data && data.youtube && data.contact) {
      setFooter(data);
    }
  }, [t]);

  return (
    <Container>
      {footer && (
        <>
          <Media>
            <Icons>
              <Icon
                href={footer.youtube.uri}
                title={footer.youtube.title}
                target="_blank"
                rel="noreferrer"
              >
                <SiYoutube />
              </Icon>
              <Icon
                href={footer.facebook.uri}
                title={footer.facebook.title}
                target="_blank"
                rel="noreferrer"
              >
                <SiFacebook />
              </Icon>
              <Icon
                href={footer.instagram.uri}
                title={footer.instagram.title}
                target="_blank"
                rel="noreferrer"
              >
                <SiInstagram />
              </Icon>
              <Icon
                href={footer.spotify.uri}
                title={footer.spotify.title}
                target="_blank"
                rel="noreferrer"
              >
                <SiSpotify />
              </Icon>
            </Icons>
          </Media>
          <Wrapper>
            <h4>{footer.contact.text}:</h4>
            <span>{footer.contact.phone}</span>
            <span>{footer.contact.mail}</span>
          </Wrapper>
        </>
      )}
    </Container>
  );
};

export default Footer;
