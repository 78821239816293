import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.footer`
  grid-area: footer;
  display: flex;
  align-items: center;
  color: #4d4237;

  @media (max-width: 768px) {
    background: #fdfdfd;
    opacity: 0.9;
    flex-direction: column;
    justify-content: center;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  font: 1.4rem 'Montserrat';

  h4 {
    color: #4d4237;
    font-size: 1.8rem;
  }
`;

export const Media = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  align-items: center;

  span {
    padding-top: 5px;
  }
`;

export const Icons = styled.div`
  display: flex;
`;

export const Icon = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background: #4d4237;
  color: #fdfdfd;
  height: 32px;
  width: 32px;

  transition: all 1s;

  + a {
    margin-left: 5px;
  }

  svg {
    height: 20px;
    width: auto;
  }

  :hover {
    background: ${darken(0.05, '#4d4237')};
  }
`;
