import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
  }

  body {
    background: #271D1D;
    color: #FDFDFD;
    height: 100%;
    max-height: 100vh;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }

  body, input, button, p {
    font: 1.6rem 'Montserrat', sans-serif;
  }

  a {
    font: 1.8rem 'Roboto', sans-serif;
    color: #BBA66D;
    text-decoration: none;
  }

  h1, h2, h3, h4, h5, h6 {
    font: 1.8rem 'Roboto', sans-serif;
    color: #BBA66D;
  }

  #root {
    max-width: 1920px;
    margin: 0 auto;
    /* height: 100vh; */
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #4d4237;
  }

  ::-webkit-scrollbar-track {
    box-shadow: grey 0px 0px 5px inset;
  }

  button {
    cursor: pointer;
  }

  /* .fade-enter {
    opacity: 0;
    z-index: 1;
  }

  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 100ms ease-in;
  } */
`;
